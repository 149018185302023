import React, { useState, useEffect } from 'react';
import FileUpload from '../components/fileUpload';
import PasteArea from '../components/pasteArea';
import SinapiReference from '../components/SinapiReference';
import './styles/importAnalyzer.css';
import { Adsense } from '@ctrl/react-adsense';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000/';

function parseDecimal(value) {
  if (typeof value === 'string') {
    return parseFloat(value.replace(',', '.'));
  }
  return value;
}

const ImportAnalyzer = () => {
  const [data, setData] = useState([]);
  const [originalSheet, setOriginalSheet] = useState([]);
  const [referenceData, setReferenceData] = useState({
    state: 'TO',
    month: 9,
    year: 2024,
    desoneracao: false,
  });

  const [columnIndexes, setColumnIndexes] = useState({
    item: 1,
    codigo: 2,
    descricao: 4,
    quantidade: 6,
  });

  const [isWideScreen, setIsWideScreen] = useState(false);

  useEffect(() => {
    const updateScreenMode = () => setIsWideScreen(window.innerWidth >= 900);
    window.addEventListener('resize', updateScreenMode);
    updateScreenMode(); // Set initial screen mode

    return () => window.removeEventListener('resize', updateScreenMode);
  }, []);

  const handleReferenceChange = (newValues) => {
    setReferenceData((prevData) => ({ ...prevData, ...newValues }));
  };

  const handleIndexChange = (e) => {
    const { name, value } = e.target;
    setColumnIndexes((prevIndexes) => ({ ...prevIndexes, [name]: parseInt(value) }));
  };

  const processExcelData = (sheet) => {
    if (sheet.length === 0) return;
    setOriginalSheet(sheet);
    updateData(sheet);
  };

  const updateData = (sheet) => {
    console.log(sheet)
    const itemIndex = columnIndexes.item - 1;
    const codigoIndex = columnIndexes.codigo - 1;
    const descricaoIndex = columnIndexes.descricao - 1;
    const quantidadeIndex = columnIndexes.quantidade - 1;

    const filteredData = sheet.map((row) => ({
      Item: row[itemIndex] || '',
      Código: row[codigoIndex] || '',
      Descrição: row[descricaoIndex] || '',
      Quantidade: parseDecimal(row[quantidadeIndex]) || 0,
    })).filter((row) => row.Código);

    setData(filteredData);
  };

  useEffect(() => {
    if (originalSheet.length > 0) {
      updateData(originalSheet);
    }
  }, [columnIndexes, originalSheet]);

  const deleteRow = (index) => {
    const newData = data.filter((_, i) => i !== index);
    setData(newData);
  };

  const getData = async () => {
    const services = data.map((row) => ({ 
        item: row.Item,
        service_code: row.Código, 
        quantity: row.Quantidade, 
        d: row.Descrição 
    }));

    console.log(services)

    // Separate valid and invalid services
    const validServices = services.filter(service => !isNaN(parseFloat(service.service_code)));
    const invalidServices = services.filter(service => isNaN(parseFloat(service.service_code)));

    try {
        const response = await fetch(`${API_URL}api/services/sinapiTimeline`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                services: validServices,
                state: referenceData.state,
                year: referenceData.year,
                month: referenceData.month,
                desoneracao: referenceData.desoneracao,
            }),
        });

        if (!response.ok) {
            throw new Error('Failed to retrieve insumos');
        }

        const { allServices, unmatchedServices } = await response.json();

        // Combine `allServices` with unmatched and invalid services
        const updatedServices = [
          ...allServices,
          ...services.filter(service => unmatchedServices.some(unmatched => unmatched.service_code === service.service_code))
              .map(service => ({
                  ...service,
                  status: 'unmatched', // Mark unmatched services for identification
              })),
          ...invalidServices.map(service => ({
              ...service,
              status: 'invalid', // Mark invalid services for identification
          })),
      ];
      

        // Store the populated services data in sessionStorage
        sessionStorage.setItem('servicesData', JSON.stringify(updatedServices));
        sessionStorage.setItem('servicesOrder', JSON.stringify(services.map(service => service.service_code)));

        // Open new window with populated services data
        window.open('/cronograma', '_blank');
    } catch (error) {
        alert(error.message);
    }
};

  

  const columnIndexFields = [
    { label: "Item", name: "item" },
    { label: "Código", name: "codigo" },
    { label: "Descrição", name: "descricao" },
    { label: "Quantidade", name: "quantidade" },
  ];

  const ColumnIndexes = (
    <div className="column-indexes">
      {columnIndexFields.map((field) => (
        <label key={field.name}>
          {field.label}:
          <input
            type="number"
            name={field.name}
            value={columnIndexes[field.name]}
            onChange={handleIndexChange}
          />
        </label>
      ))}
    </div>
  );

  // Wide screen layout
  const renderWideScreenLayout = () => (
<div className="import-analyzer-container">
      <div className="functional-area">
        {/* SinapiReference Section */}
        
        <SinapiReference
          state={referenceData.state}
          month={referenceData.month}
          year={referenceData.year}
          desoneracao={referenceData.desoneracao}
          setState={(value) => handleReferenceChange({ state: value })}
          setMonth={(value) => handleReferenceChange({ month: value })}
          setYear={(value) => handleReferenceChange({ year: value })}
          setDesoneracao={(value) => handleReferenceChange({ desoneracao: value })}
        />
  
        {/* Input Section */}
        
        <div className="input-section">
          <h3 className="input-title">Posição da Coluna</h3>
          <div className="column-indexes">
          {ColumnIndexes}
          </div>
        </div>

  
        {/* Upload and Paste Area Section */}
        <div className="upload-section">
          <FileUpload onProcessData={processExcelData} />
          <PasteArea onProcessData={processExcelData} />
        </div>
  
        {/* Action Section */}


      </div>

      <div className="action-section">
          <button className="btn-generate" onClick={getData}>Listar Insumos</button>
          
        </div>

      {/* Data Table Section */}
      {data.length > 0 && (
        <div>

          {data.length > 0 && <Table data={data} onDelete={deleteRow} />}
        
        </div>
      )}
    </div>
  );


  // Narrow screen layout
  const renderNarrowScreenLayout = () => (
    <div className="import-analyzer-narrow">
      <div className="upload-section">
        <FileUpload onProcessData={processExcelData} />
      </div>
      <SinapiReference {...referenceData} onChange={handleReferenceChange} />
      <div className="column-indexes">
        {ColumnIndexes}
      </div>
      <div className="action-section">
          <button className="btn-generate" onClick={getData}>Listar Insumos</button>
      </div>
      {data.length > 0 && <Table data={data} onDelete={deleteRow} />}
    </div>
  );

  return isWideScreen ? renderWideScreenLayout() : renderNarrowScreenLayout();
};

// Reusable Table component
const Table = ({ data, onDelete }) => (
  <div className="table-section">
    <Adsense
      style={{ display: 'block', minWidth: '300px', maxHeight: '350px', maxWidth: '95vw', margin: 'auto' }}
      client="ca-pub-2344773042588967"
      slot="1408413103"
      data-ad-format="rectangle, horizontal"
      data-full-width-responsive="true"
    />
    <table className="data-table">
      <thead>
        <tr>
          <th>Índice</th>
          <th>Código</th>
          <th>Descrição</th>
          <th>Quantidade</th>
          <th>Ações</th>
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <tr key={index}>
            <td>{row.Item}</td>
            <td>{row.Código}</td>
            <td>{row.Descrição}</td>
            <td>{row.Quantidade}</td>
            <td>
              <button onClick={() => onDelete(index)}>Excluir</button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

export default ImportAnalyzer;
