import React, { useState } from 'react';

const EditServiceModal = ({ service, onSave, onClose, servicesData }) => {
  const [editedService, setEditedService] = useState({
    ...service,
    waitingPeriods: service.waitingPeriods || [],
    timingMode: 'Começo e Duração', // Default timing mode
    beginType: 'After Predecessor', // Default begin type
    endType: 'After Specific Service', // Default end type
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;


    
      setEditedService((prev) => {
        const updatedService = { ...prev, [name]: value };
    
        // Handle specific timing-related fields
        if (name === 'timingMode') {
          switch (value) {
            case 'Começo e Duração':
              return {
                ...updatedService,
                end: null, // Clear end if not used
                endType: null,
              };
            case 'Duração e Fim':
              return {
                ...updatedService,
                start: null, // Clear start if not used
                beginType: null,
              };
            case 'Começo e Fim':
              return {
                ...updatedService,
                duration: null, // Clear duration if not used
              };
            default:
              return updatedService;
          }
        }
    
        // Handle numeric fields like quantity and duration
        if (name === 'quantity' || name === 'duration') {
          return {
            ...updatedService,
            [name]: parseFloat(value),
          };
        }
    
        return updatedService;
      });
   
    
  };

  const validate = () => {
    const { start, end, duration, timingMode } = editedService;
    const newErrors = {};

    if (timingMode === 'Começo e Fim' && start && end && duration) {
      newErrors.logic = 'You can only define two of Start, End, and Duration.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = (type) => {
    if (validate()) {
      onSave(editedService, type);
      onClose();
    }
  };

  const renderTimingFields = () => {
    const { timingMode, beginType, endType } = editedService;
  
    const renderBeginType = () => (
      <>
        <label>
          Begin Type:
          <select
            name="beginType"
            value={beginType}
            onChange={(e) =>
              setEditedService((prev) => ({ ...prev, beginType: e.target.value }))
            }
          >
            <option value="After Predecessor">After Predecessor</option>
            <option value="After Predecessor, Including Waiting Period">
              After Predecessor, Including Waiting Period
            </option>
            <option value="Dia Específico">Dia Específico</option>
            <option value="Começo da Obra">Começo da Obra</option>
          </select>
        </label>
        {beginType === 'Dia Específico' && (
          <label>
            Specific Start Date:
            <input
              type="date"
              name="start"
              value={editedService.start || ''}
              onChange={handleChange}
            />
          </label>
        )}
      </>
    );
  
    const renderEndType = () => (
      <>
        <label>
          End Type:
          <select
            name="endType"
            value={endType}
            onChange={(e) =>
              setEditedService((prev) => ({ ...prev, endType: e.target.value }))
            }
          >
            <option value="After Specific Service">After Specific Service</option>
            <option value="After Specific Service, Including Waiting Period">
              After Specific Service, Including Waiting Period
            </option>
            <option value="Dia Específico">Dia Específico</option>
            <option value="Fim da Obra">Fim da Obra</option>
          </select>
        </label>
        {endType === 'Dia Específico' && (
          <label>
            Specific End Date:
            <input
              type="date"
              name="end"
              value={editedService.end || ''}
              onChange={handleChange}
            />
          </label>
        )}
      </>
    );
  
    switch (timingMode) {
      case 'Começo e Duração':
        return (
          <>
            {renderBeginType()}
            <label>
              Duration (Days):
              <input
                type="number"
                name="duration"
                value={editedService.duration || ''}
                onChange={handleChange}
              />
            </label>
          </>
        );
  
      case 'Duração e Fim':
        return (
          <>
            <label>
              Duration (Days):
              <input
                type="number"
                name="duration"
                value={editedService.duration || ''}
                onChange={handleChange}
              />
            </label>
            {renderEndType()}
          </>
        );
  
      case 'Começo e Fim':
        return (
          <>
            {renderBeginType()}
            {renderEndType()}
          </>
        );
  
      default:
        return null;
    }
  };
  

  return (
    <div className="modal">
      <h3>Edit Service</h3>
      {errors.logic && <p className="error">{errors.logic}</p>}

      {/* Predecessor */}
      <label>
        Predecessor:
        <select
          name="predecessor"
          value={editedService.predecessors[0] || ''}
          onChange={(e) =>
            setEditedService((prev) => ({
              ...prev,
              predecessors: [e.target.value],
            }))
          }
        >
          <option value="">None</option>
          {servicesData.map((s) => (
            <option key={s.id} value={s.id}>
              {s.service_code}
            </option>
          ))}
        </select>
      </label>

      {/* Timing Mode */}
      <label>
        Define Timing By:
        <select
          name="timingMode"
          value={editedService.timingMode}
          onChange={(e) =>
            setEditedService((prev) => ({ ...prev, timingMode: e.target.value }))
          }
        >
          <option value="Começo e Duração">Começo e Duração</option>
          <option value="Duração e Fim">Duração e Fim</option>
          <option value="Começo e Fim">Começo e Fim</option>
        </select>
      </label>

      {renderTimingFields()}

      {/* Quantity */}
      <label>
        Quantity:
        <input
          type="number"
          name="quantity"
          value={editedService.quantity}
          onChange={handleChange}
        />
      </label>



      {/* Save and Cancel Buttons */}
      <button onClick={() => handleSave('default')}>Save</button>
      <button onClick={() => handleSave('isolated')}>Save Isolated</button>
      <button onClick={onClose}>Cancel</button>
    </div>
  );
};

export default EditServiceModal;
